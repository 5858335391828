import './Footer.css';

import React from 'react';
import discord from '../assets/social/discord.svg';
import github from '../assets/social/github.svg';
import mirror from '../assets/social/mirror.svg';
import telegram from '../assets/social/telegram.svg';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <footer>
      <div className="container">
        <div>
          &copy; 2021-{new Date().getFullYear()} &nbsp; <a href="https://chainbow.co.jp">ChainBow Co, Ltd.</a>
          <span className="company-address"> 2-6-6-404, Taito, Taito-Ku, Tokyo, 110-0016, Japan.</span>
        </div>

        <div className="social">
          {/* <a href="https://discord.gg/xaaUbPBFxM">Talk to us</a> */}
          <a href="https://discord.gg/xaaUbPBFxM">
            <img src={discord} alt="Discord" style={{ height: 24, marginTop: 2 }} />
          </a>

          <a href="https://github.com/wallet3">
            <img src={github} alt="Github" />
          </a>

          <a className="brand-kit" href="/brand.zip">
            Brand Kit
          </a>

          {/* <a href="https://t.me/wallet3_io">
            <img src={telegram} alt="Telegram" style={{ height: 19, marginTop: -1 }} />
          </a>

          <a href="https://mirror.xyz">
            <img src={mirror} alt="Twitter" style={{ height: 17 }} />
          </a> */}
        </div>
      </div>
    </footer>
  );
};
