import './App.css';
import AppShow from './components/AppShow';
import Features from './components/Features';
import Footer from './components/Footer';
import Header from './components/Header';
import Layer2 from './components/Sections/Layer2';
import SecuritySection from './components/Sections/Security';
import Web3Section from './components/Sections/Web3';
import { Routes, Route, Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Connect from './components/Connect';
import Downloads from './components/Downloads';

const version = '1.11.0';
const mobileVersion = '3.6.1';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => {
  return (
    <div id="app" className="app">
      <Header />
      <Routes>
        <Route key={'/'} path="/" element={<Home />} />
        <Route key={'/wc'} path="wc" element={<WC />} />
      </Routes>
      <Footer />
    </div>
  );
};

function Home() {
  return (
    <main>
      <div className="app-overview">
        <Features mobileVersion={mobileVersion} desktopVersion={version} />
        <AppShow />
      </div>

      <SecuritySection />
      <Web3Section />
      <Layer2 />
    </main>
  );
}

function WC() {
  let [params] = useSearchParams();
  const uri = params.get('uri');

  useEffect(() => {
    // @ts-ignore
    window.location = `${uri}`;
    setTimeout(() => {}, 25);
  }, []);

  return (
    <main>
      <div className="app-overview">
        {uri && <Connect uri={uri} mobileVersion={mobileVersion} desktopVersion={version} />}
        <AppShow />
      </div>
    </main>
  );
}
